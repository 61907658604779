.my-facebook-button {
    background-color: #1877f2; /* Facebook blue */
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    transition: background-color 0.3s ease;
  }
  
  .my-facebook-button:hover {
    background-color: #145dbf; /* Darker blue on hover */
  }
  
  .my-facebook-button i {
    margin-right: 8px; /* Space between the icon and text */
    font-size: 20px;
  }
  
  